export default function ChevronRight() {
    return (
        <svg className="" width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
            <g id="Home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="My-Workspace-Homepage" transform="translate(-1529.000000, -1923.000000)">
                    <g id="Group-23" transform="translate(375.000000, 1901.000000)">
                        <g id="Buttons/Thirdy/Main" transform="translate(1095.000000, 22.000000)">
                            <g id="Group-2" transform="translate(59.000000, 0.000000)">
                                <rect id="Rectangle" fill="#FFFFFF" opacity="0" x="0" y="0" width="16" height="16"></rect>
                                <path d="M11,8.85714299 L6.14285933,4 L5,5.1428573 L8.71428597,8.8571426 L5,12.5714263 L6.14285692,13.7142837 L11,8.85714299 Z" id="arrow-/-chevron_right" fill="#10744A" fillRule="nonzero"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

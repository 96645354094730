import ChevronRight from '../libs/ChevronRight.tsx'
import {Branch} from '../types'

type Props = {
    branch: Branch | null
    hidden?: boolean
}

export default function BranchInfoOverlay(props: Props) {
    const {branch, hidden} = props

    return (
        <div className={`mwbm-branch-info-overlay ${hidden ? 'hidden' : ''}`}>
            <div className="mwbm-branch-info">
                <h4 className="title">
                    {branch?.title}
                </h4>
                <address className="address">
                    {branch?.address}
                </address>
                <a
                    className="telephone"
                    href={`${branch?.telephone} ? tel://${branch?.telephone} : '#'`}
                >
                    {branch?.telephone}
                </a>
            </div>
            <div className="mwbm-naver-map-url">
                <a href={`${branch?.map_url ? branch?.map_url : '#'}`} target="_blank">
                    <span>See on Naver Map</span>
                    <ChevronRight/>
                </a>
            </div>
        </div>
    )
}

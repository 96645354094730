import {useEffect, useRef, useState} from 'react'
import NaverMap from '../libs/NaverMap.tsx'
import BranchInfoOverlay from './BranchInfoOverlay.tsx'
import {Branch} from '../types'
import './App.css'

declare global {
    const mwbmMultiBranchMap: {
        data: {
            className: string
            id: string
            branches: Branch[]
            height: string
            markerIcon: string
            width: string
        }
    }
}

export default function App() {
    console.log('mwbmMultiBranchMap', mwbmMultiBranchMap)

    const {
        className,
        id,
        branches,
        height,
        markerIcon,
        width,
    } = mwbmMultiBranchMap.data

    const [branchIndex, setBranchIndex] = useState<number>(-1),
        [showOverlay, setShowOverlay] = useState<boolean>(false)

    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (ref.current) {
            const root: HTMLDivElement | null = ref.current.closest('[data-mwbm-app-root]')
            if (root) {
                root.style.width = width;
                root.style.height = height;
            }
            ref.current.parentNode?.removeChild(ref.current)
        }
    }, [])

    return (
        <div className="mwbm-naver-map-wrap">
            <NaverMap
                id={id}
                className={className}
                markers={branches.map((branch) => ({
                    ...branch.coord,
                    title: branch.title,
                }))}
                onClickMarker={(marker: naver.maps.Marker) => {
                    const title = marker.getTitle(),
                        idx = branches.findIndex((b) => b.title == title)

                    if (idx > -1) {
                        if (idx === branchIndex) {
                            setShowOverlay(!showOverlay)
                        } else {
                            setShowOverlay(true)
                        }
                        setBranchIndex(idx)
                    }
                }}
                markerIcon={markerIcon ?? undefined}
            />
            <BranchInfoOverlay
                branch={branchIndex > -1 ? branches[branchIndex] : null}
                hidden={!showOverlay}
            />
            <span className="dummy-span" ref={ref}></span>
        </div>
    )
}
